<template>
  <div class="create-user h-full relative pt-12 sm:pt-6">
    <div class="flex">
      <RouterLink
        :to="{ name: 'companies.query' }"
        class="flex items-center gap-2 mb-8 go-back"
      >
        <div class="bg-zinc-100 p-3 rounded-full go-back__icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67"
              stroke="#090909"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h1 class="text-3xl font-bold font-nunito">Cadastrar usuário</h1>
      </RouterLink>
    </div>
    <section class="flex flex-col gap-4 grid grid-cols-12">
      <div class="font-inter flex flex-col gap-2 col-span-6 text-sm">
        <label for=""
          ><strong class="text-lg">CNPJ</strong> (obrigatório)</label
        >
        <input
          type="text"
          placeholder="ex: 000.000.000-00"
          v-maska="
            (payload.document ?? '').length <= 14
              ? '###.###.###-###'
              : '##.###.###/####-##'
          "
          v-model="payload.document"
        />
      </div>
      <div class="font-inter flex flex-col gap-2 col-span-6">
        <label for=""
          ><strong class="text-lg">Razão Social</strong> (obrigatório)</label
        >
        <input type="text" v-model="payload.name" readonly />
      </div>
      <div class="font-inter flex flex-col gap-2 col-span-6">
        <label for=""
          ><strong class="text-lg">Email</strong> (obrigatório)</label
        >
        <input
          type="text"
          placeholder="ex: email@gmail.com"
          v-model="payload.email"
        />
      </div>
      <div class="font-inter flex flex-col gap-2 col-span-6">
        <label for=""
          ><strong class="text-lg">Senha</strong> (obrigatório)</label
        >
        <input
          type="text"
          placeholder="ex: 123456"
          v-model="payload.password"
        />
      </div>
      <div class="col-span-12 flex flex-col gap-4">
        <section class="flex flex-col gap-4 grid grid-cols-12">
          <div class="col-span-12 pt-4 flex items-center gap-4">
            <div>
              <h2 class="text-xl font-inter">
                <strong>Endereço do cliente</strong>
              </h2>
              <p class="text-6f font-inter text-sm">
                Adicione o máximo de informações do endereço do cliente.
              </p>
            </div>
            <button
              v-if="
                payload.document?.length === 18 &&
                !isAddressRequestLoading &&
                !payload.address?.postal_code
              "
              class="border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 add"
              @click="fillAddress"
            >
              Preencher endereço
            </button>
            <SpinnerLoader v-if="isAddressRequestLoading" />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">CEP</strong> (obrigatório)</label
            >
            <input
              type="text"
              v-maska="'#####-###'"
              v-model="payload.address!.postal_code"
              readonly
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Rua</strong> (obrigatório)</label
            >
            <input type="text" v-model="payload.address!.street" readonly />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Número</strong> (obrigatório)</label
            >
            <input type="text" v-model="payload.address!.number" readonly />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Bairro</strong> (obrigatório)</label
            >
            <input
              type="text"
              v-model="payload.address!.neighborhood"
              readonly
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Cidade</strong> (obrigatório)</label
            >
            <input type="text" v-model="payload.address!.city" readonly />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Estado</strong> (obrigatório)</label
            >
            <input type="text" v-model="payload.address!.state" readonly />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Complemento</strong> (opcional)</label
            >
            <input type="text" v-model="payload.address!.complement" readonly />
          </div>
        </section>
      </div>
    </section>
    <nav>
      <input
        v-if="!isCreateUserRequestLoading"
        type="button"
        value="Criar"
        @click="createUser"
        :disabled="
          !payload.name ||
          !payload.document ||
          (payload.document?.length != 14 && payload.document?.length != 18) ||
          !payload.email ||
          !payload.password ||
          !isAddressValid
        "
      />
      <SpinnerLoaderButton v-else :height="60" :maxWidth="464" />
      <input
        type="button"
        value="Cancelar"
        @click="$router.push('/admin/home')"
      />
    </nav>
  </div>
</template>

<script lang="ts" setup>
import axiosClient from "@/api/axios";
import { vMaska } from "maska/vue";
import { ref, computed } from "vue";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import { toast } from "vue3-toastify";
import SpinnerLoaderButton from "@/components/loader/SpinnerLoaderButton.vue";

const payload = ref<any>({ address: {} });
const isCreateUserRequestLoading = ref(false);
const isAddressRequestLoading = ref(false);
const isAddressValid = computed(() => {
  if (payload.value.address?.postal_code?.length !== 9) {
    return false;
  }

  return (
    payload.value.address.street &&
    payload.value.address.number &&
    payload.value.address.neighborhood &&
    payload.value.address.city &&
    payload.value.address.state
  );
});

function createUser() {
  isCreateUserRequestLoading.value = true;

  axiosClient
    .post("/sessions/register", payload.value)
    .then((res) => {
      payload.value = { address: {} };
      toast.success("Usuário cadastrado com sucesso");
    })
    .finally(() => (isCreateUserRequestLoading.value = false));
}

function fillAddress() {
  if (payload.value.document.length === 18) {
    isAddressRequestLoading.value = true;
    axiosClient
      .get(
        `https://publica.cnpj.ws/cnpj/${payload.value.document.replace(
          /\D/g,
          ""
        )}`
      )
      .then((res: any) => {
        payload.value.address.postal_code = res.data.estabelecimento.cep;
        payload.value.address.street = res.data.estabelecimento.logradouro;
        payload.value.address.number = res.data.estabelecimento.numero;
        payload.value.address.neighborhood = res.data.estabelecimento.bairro;
        payload.value.address.city = res.data.estabelecimento.cidade.nome;
        payload.value.address.state = res.data.estabelecimento.estado.sigla;
        payload.value.address.complement =
          res.data.estabelecimento.estado.complemento;
        payload.value.name = res.data.razao_social;
      })
      .catch(() => {
        toast.error(
          "Erro ao buscar endereço, verifique se o CNPJ está correto"
        );
        payload.value.document = "";
      })
      .finally(() => (isAddressRequestLoading.value = false));
  }
}
</script>

<style lang="scss">
$primary-color: #059669;
$primary-color-hover: rgb(35, 103, 35) 69;

.create-user {
  input:disabled {
    background: #f1f1f1 !important;
    color: #6f6f6f !important;
    cursor: not-allowed !important;
  }
  input {
    height: 3.4375rem;
    border-radius: 0.875rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px;
    outline: unset;
    color: $primary-color;
    font-family: "Inter", sans-serif;
    &::placeholder {
      color: #484848;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: color 0.2s;
    }
    &:focus::placeholder {
      color: transparent;
    }
    &:read-only {
      background-color: #f5f5f5; // exemplo de fundo cinza claro
      color: #a1a1a1; // exemplo de cor do texto mais claro
      border-color: #ccc; // exemplo de cor da borda
    }
  }
  .go-back {
    transition: all 0.2s ease;
    &__icon-wrapper {
      transition: all 0.2s ease;
      path {
        transition: all 0.2s ease;
      }
    }
    &:hover {
      color: #059669;
    }
    &:hover .go-back__icon-wrapper {
      background: #059669;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
  nav {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 32px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    position: fixed;
    bottom: 5px;
    column-gap: 20px;
    margin: auto 0 0 0;
    input {
      border: none;
      cursor: pointer;
      &:first-child {
        width: 100%;
        max-width: 464px;
        height: 60px;
        border-radius: 30px;
        background: $primary-color;
        color: #fff;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: background 0.2s;
        &:hover {
          background: $primary-color-hover;
        }
      }
      &:last-child {
        color: #737373;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: unset;
        transition: color 0.2s;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .add {
    font-family: Inter, "sans-serif";
    transition: all 0.2s ease;
    path {
      transition: all 0.2s ease;
    }
    &:hover {
      background: #059669;
      color: #fff;
      path {
        stroke: #fff;
      }
    }
  }
}

@media (max-width: 600px) {
  .create-user {
    padding-bottom: 5.5rem;
    nav {
      width: 100vw;
      position: fixed;
      left: 0;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
      background: #fff;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 15px;
      box-sizing: border-box;
      z-index: 4;
    }
  }
}
</style>
