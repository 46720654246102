export default function useCookie() {
  function getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const cookieValue = parts.pop()?.split(';').shift();
        return cookieValue ? decodeURIComponent(cookieValue) : undefined;
    }
    return undefined;
}

  function removeCookie(name: string, path: string = '/') {
    document.cookie = `${name}=; path=${path}; domain=${process.env.VUE_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; SameSite=Lax`;
  }


  return {
    getCookie,
    removeCookie
  }
}