<template>
  <div class="main-layout">
    <aside
      class="main-layout__sidebar font-inter p-4 flex flex-col gap-6"
      :class="isSidebarOpen ? 'open' : ''"
    >
      <div class="flex items-center gap-4 pb-5 pt-4">
        <img
          class="main-layout__sidebar--logo"
          src="@/assets/images/fraude_zero.png"
        />
      </div>
      <div class="flex flex-col gap-8">
        <div>
          <RouterLink
            :to="{ name: 'companies.query' }"
            class="flex items-center justify-between gap-4 hover:stroke-primary cursor-pointer"
            :class="
              $route.path.startsWith('/companies') || isCompaniesMenuOpen
                ? 'router-link-active'
                : ''
            "
            @click="isSidebarOpen = false"
          >
            <div class="flex items-center gap-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3.00977 11.22V15.71C3.00977 20.2 4.80977 22 9.29977 22H14.6898C19.1798 22 20.9798 20.2 20.9798 15.71V11.22"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary"
                />
                <path
                  d="M12 12C13.83 12 15.18 10.51 15 8.68L14.34 2H9.66999L8.99999 8.68C8.81999 10.51 10.17 12 12 12Z"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary"
                />
                <path
                  d="M18.3098 12C20.3298 12 21.8098 10.36 21.6098 8.35L21.3298 5.6C20.9698 3 19.9698 2 17.3498 2H14.2998L14.9998 9.01C15.1698 10.66 16.6598 12 18.3098 12Z"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary"
                />
                <path
                  d="M5.63988 12C7.28988 12 8.77988 10.66 8.93988 9.01L9.15988 6.8L9.63988 2H6.58988C3.96988 2 2.96988 3 2.60988 5.6L2.33988 8.35C2.13988 10.36 3.61988 12 5.63988 12Z"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary"
                />
                <path
                  d="M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary"
                />
              </svg>
              Consultar empresa
            </div>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 15 8" stroke="none" class="transition-transform duration-200" :style="{transform: isCompaniesMenuOpen ? 'rotate(180deg)':''}"><path d="M7.5,105.14a1.085,1.085,0,0,1-.743-.288L.308,98.818a.939.939,0,0,1,0-1.39,1.1,1.1,0,0,1,1.486,0l5.707,5.34,5.707-5.339a1.1,1.1,0,0,1,1.485,0,.938.938,0,0,1,0,1.39l-6.45,6.035A1.085,1.085,0,0,1,7.5,105.14Z" transform="translate(-0.001 -97.14)"></path></svg> -->
          </RouterLink>
          <!-- <div class="grid transition-all duration-200" :style="{gridTemplateRows: isCompaniesMenuOpen ? '1fr':'0fr'}">
                        <div class="overflow-hidden pl-4 transition-padding duration-200" :class="isCompaniesMenuOpen ? 'pt-2':''">
                            <RouterLink :to="{name:'companies'}" class="flex items-center justify-between gap-4 mb-2 hover:stroke-primary" href="">
                                <div class="flex items-center gap-2">
                                    <div class="bg-zinc-600 w-2 h-2 rounded-full mr-2"></div>
                                    Gerenciar
                                </div>
                            </RouterLink>
                            <RouterLink :to="{name:'companies.create'}" class="flex items-center justify-between gap-4 mb-2 hover:stroke-primary" href="">
                                <div class="flex items-center gap-2">
                                    <div class="bg-zinc-600 w-2 h-2 rounded-full mr-2"></div>
                                    Criar
                                </div>
                            </RouterLink>
                            <RouterLink :to="{name:'companies.query'}" class="flex items-center justify-between gap-4 hover:stroke-primary" href="">
                                <div class="flex items-center gap-2">
                                    <div class="bg-zinc-600 w-2 h-2 rounded-full mr-2"></div>
                                    Consultar
                                </div>
                            </RouterLink>
                        </div>
                    </div> -->
        </div>
        <!-- <div>
                    <RouterLink :to="{ name: 'customers.query' }" class="flex items-center justify-between gap-4 hover:fill-primary cursor-pointer" :class="$route.path.startsWith('/customers/create') || isCompaniesMenuOpen ? 'router-link-active':''" @click="isSidebarOpen = false">
                        <div class="flex items-center gap-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C9.66 2.75 7.75 4.66 7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75Z" fill="#171717" class="hover:parent:fill-primary"/>
                                <path d="M3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22C2.66016 17.73 6.85018 14.25 12.0002 14.25C12.4102 14.25 12.7502 14.59 12.7502 15C12.7502 15.41 12.4102 15.75 12.0002 15.75C7.68018 15.75 4.16016 18.55 4.16016 22C4.16016 22.41 3.82016 22.75 3.41016 22.75Z" fill="#171717" class="hover:parent:fill-primary"/>
                                <path d="M18.2 22.15C16.02 22.15 14.25 20.38 14.25 18.2C14.25 16.02 16.02 14.25 18.2 14.25C20.38 14.25 22.15 16.02 22.15 18.2C22.15 20.38 20.38 22.15 18.2 22.15ZM18.2 15.75C16.85 15.75 15.75 16.85 15.75 18.2C15.75 19.55 16.85 20.65 18.2 20.65C19.55 20.65 20.65 19.55 20.65 18.2C20.65 16.85 19.55 15.75 18.2 15.75Z" fill="#171717" class="hover:parent:fill-primary"/>
                                <path d="M22 22.75C21.81 22.75 21.6199 22.68 21.4699 22.53L20.4699 21.53C20.1799 21.24 20.1799 20.7599 20.4699 20.4699C20.7599 20.1799 21.24 20.1799 21.53 20.4699L22.53 21.4699C22.82 21.7599 22.82 22.24 22.53 22.53C22.38 22.68 22.19 22.75 22 22.75Z" fill="#171717" class="hover:parent:fill-primary"/>
                            </svg>
                            Consultar cliente
                        </div>
                    </RouterLink>
                </div> -->
      </div>
      <div>
        <h6>Seus dados</h6>
        <div class="flex flex-col gap-4">
          <div>
            <RouterLink
              :to="{ name: 'profile' }"
              class="flex items-center justify-between gap-4 hover:stroke-primary cursor-pointer"
              :class="
                $route.path.startsWith('/profile') ? 'router-link-active' : ''
              "
              @click="isSidebarOpen = false"
            >
              <div class="flex items-center gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="hover:parent:stroke-primary"
                  />
                  <path
                    d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="hover:parent:stroke-primary"
                  />
                </svg>
                Perfil
              </div>
            </RouterLink>
          </div>
          <div>
            <RouterLink
              :to="{ name: 'credentials' }"
              class="flex items-center justify-between gap-4 hover:stroke-primary cursor-pointer hover:stroke-primary "
              :class="
                $route.path.startsWith('/credentials') ? 'router-link-active' : ''
              "
              @click="isSidebarOpen = false"
            >
              <div class="flex items-center gap-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19.7901 14.9301C17.7301 16.9801 14.7801 17.6101 12.1901 16.8001L7.48015 21.5001C7.14015 21.8501 6.47015 22.0601 5.99015 21.9901L3.81015 21.6901C3.09015 21.5901 2.42015 20.9101 2.31015 20.1901L2.01015 18.0101C1.94015 17.5301 2.17015 16.8601 2.50015 16.5201L7.20015 11.8201C6.40015 9.22007 7.02015 6.27007 9.08015 4.22007C12.0301 1.27007 16.8201 1.27007 19.7801 4.22007C22.7401 7.17007 22.7401 11.9801 19.7901 14.9301Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
                  <path d="M6.89014 17.49L9.19014 19.79" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
                  <path d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
                </svg>
                Credenciais
              </div>
            </RouterLink>
          </div>
          <div>
            <a
              @click="logout"
              class="flex justify-between items-center gap-4 cursor-pointer hover:stroke-primary"
            >
              <div class="flex items-center gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="hover:parent:stroke-primary"
                  />
                  <path
                    d="M15 12H3.62"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="hover:parent:stroke-primary"
                  />
                  <path
                    d="M5.85 8.65002L2.5 12L5.85 15.35"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="hover:parent:stroke-primary"
                  />
                </svg>
                Sair
              </div>
            </a>
          </div>
        </div>
      </div>
    </aside>
    <main class="h-full main">
      <button
        @click="isSidebarOpen = !isSidebarOpen"
        class="hidden fixed top-4 left-4 open-sidebar-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 7H21"
            stroke="#171717"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M3 12H21"
            stroke="#171717"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M3 17H21"
            stroke="#171717"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div id="view" style="width: 80rem">
        <RouterView />
      </div>
    </main>
    <MyModal
      v-if="authenticatedUser"
      :isOpen="isContractTermsModalOpen"
      :toggleModal="(v: boolean) => !v ? refuseContract() : ''"
      :id="'contract-terms'"
      :width="'600px'"
      :height="'800px'"
    >
      <ContractTerms :authenticatedUser="authenticatedUser" @onAccept="acceptContract" @onRefuse="refuseContract" />
    </MyModal>
    <MyModal
      :height="'610px'"
      :isOpen="is2FAuthenticationQrCodeModalOpen"
      :toggleModal="(v: boolean) => { }"
      :id="'ScanTwoFAQrCodeModal'"
      :zIndex="40"
    >
      <ScanTwoFAQrCodeModal
        :qrCode="qrCode"
        @onFirstCodeConfirmation="is2FAuthenticationQrCodeModalOpen = false"
      />
    </MyModal>
    <MyModal
      :height="'385px'"
      :width="'400px'"
      :isOpen="isActivateTwoFAModalOpen"
      :toggleModal="(v: boolean) => {}"
      :id="'ActiveTwoFA'"
      :zIndex="40"
    >
      <ActiveTwoFA @onCodeValidated="isActivateTwoFAModalOpen = false" />
    </MyModal>
  </div>
</template>

<script lang="ts" setup>
import { isSidebarOpen } from "@/global/states/GlobalState";
import useCookie from "@/composables/useCookie";
import { onMounted, ref } from "vue";
import MyModal from "@/components/modal/MyModal.vue";
import ScanTwoFAQrCodeModal from "./components/ScanTwoFAQrCodeModal.vue";
import ActiveTwoFA from "./components/ActiveTwoFA.vue";
import ContractTerms from "./components/ContractTerms.vue";
import axiosClient from "@/api/axios";

const { getCookie, removeCookie } = useCookie();
const isCompaniesMenuOpen = ref(false);
const isContractTermsModalOpen = ref(false);
const is2FAuthenticationQrCodeModalOpen = ref(false);
const isActivateTwoFAModalOpen = ref(false);
const authenticatedUser = ref<any>(
  getCookie("authenticated_user")
    ? JSON.parse(getCookie("authenticated_user") as string)
    : { address: {} }
);
const qrCode = ref("");

onMounted(() => {
  verifyAuthCookie();
  get2af();
});

function verifyAuthCookie() {
  if (!getCookie("auth_token")) {
    window.location.href = process.env?.VUE_APP_LOGIN_URL;
  } else if (!localStorage.getItem("contract_accepted")) {
    isContractTermsModalOpen.value = true;
  }
}

function get2af() {
  if (
    getCookie("is2AF") !== "true" &&
    !localStorage.getItem("hasValidated2AF")
  ) {
    is2FAuthenticationQrCodeModalOpen.value = true;
    axiosClient.post("accounts/2af").then((res) => {
      qrCode.value = res.data.qrCode;
    });
  } else if (!localStorage.getItem("hasValidated2AF")) {
    isActivateTwoFAModalOpen.value = true;
  }
}

function acceptContract() {
  localStorage.setItem("contract_accepted", "true");
  isContractTermsModalOpen.value = false;
}

function refuseContract() {
  isContractTermsModalOpen.value = false;
  removeCookie("auth_token");
  removeCookie("authenticated_user");
  window.location.href = process.env?.VUE_APP_LOGIN_URL;
}

function logout() {
  removeCookie("auth_token");
  removeCookie("authenticated_user");
  removeCookie("is2AF");
  localStorage.removeItem("hasValidated2AF");
  window.location.href = process.env?.VUE_APP_LOGIN_URL;
}
</script>

<style lang="scss">
#app {
  height: 100vh;

  .main-layout {
    height: 100%;

    #view {
      height: 100%;
    }
  }
}

.main-layout {
  &__sidebar {
    width: 270px;
    position: fixed;
    left: 0;
    height: 100%;
    background-color: #f8fafb;

    &--logo {
      width: 202px;
      height: 60px;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: #292f31;
      padding: 8px 15px 8px 9px;
      transition: all 0.2s;
      border-radius: 0.5rem;

      path.hover\:parent\:stroke-primary {
        transition: all 0.2s;
      }

      &:hover {
        background: #e0efdc;
        color: $primary-color;

        .hover\:parent\:stroke-primary {
          stroke: $primary-color;
        }

        .hover\:parent\:fill-primary {
          fill: $primary-color;
        }
      }

      &.router-link-active {
        background: #e0efdc;
        color: $primary-color;

        .hover\:parent\:stroke-primary {
          stroke: $primary-color;
        }

        .hover\:parent\:fill-primary {
          fill: $primary-color;
        }
      }
    }

    h6 {
      color: #999;
      font-size: 12px;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }
  }

  .navbar {
    margin-left: 14%;
    background-color: #f8fafb;
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .main {
    margin-left: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    justify-content: center;

    #view {
      max-width: 1200px;
    }
  }

  .modal#contract-terms {
    h2 {
      color: #22c55e;
      font-size: 1.25rem;
      font-weight: 600;
      font-family: "Nunito", sans-serif;
    }

    .modal-body {
      max-height: 83%;
      overflow: auto;

      .use-terms {
        color: #6b6a6a;
        font-size: 14px;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: #6b6a6a;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 0;

      button:first-child {
        border-radius: 30px;
        background: $primary-color;
        font-weight: 800;
        font-family: "Inter", sans-serif;
        padding: 1rem 4rem;
        color: #fff;
      }

      button:last-child {
        color: rgb(216, 70, 70);
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  main {
    #view {
      max-width: 1000px !important;
    }
  }
}

@media screen and (max-width: 1300px) {
  main {
    #view {
      max-width: 900px !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  main {
    #view {
      max-width: 800px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .main-layout {
    &__sidebar {
      transform: translate3d(-100%, 0, 0);
      overflow: hidden;
      padding-top: 2rem;
      transition: transform 0.2s;

      &.open {
        transform: translate3d(0, 0, 0);
        width: 100%;
        z-index: 10;
      }
    }

    main {
      margin-left: 1rem !important;
      margin-right: 1rem;

      .open-sidebar-btn {
        display: block;
        z-index: 10;
      }

      #view {
        width: 100% !important;
      }
    }
  }
}
</style>
