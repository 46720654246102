<template>
  <div class="p-6 h-full">
    <h2 class="pb-4">Termos de contrato</h2>
    <div class="modal-body">
      <p class="use-terms">Termos de Uso atualizado em 09:34 22/08/2024</p>
      <br />
      <div class="pt-4">
        <p>
          <strong
            >CONTRATO DE ADES&Atilde;O E USO DO SISTEMA DE BLOQUEIO DE CNPJs
            E CPFs</strong
          ><strong><br /></strong><strong><br /></strong>
          <strong>PARTES:</strong><strong><br /></strong
          ><strong><br /></strong
          ><span style="font-weight: 400">
            1. EXPANDER SISTEMAS E SOLU&Ccedil;&Otilde;ES, pessoa
            jur&iacute;dica de direito privado, inscrita no CNPJ sob n&ordm;
            56.077.610/0001-48, com sede em Rua Copaiba Lote, 01.Sala 010 A
            1017, Norte (Aguas Claras), 71919-540, Bras&iacute;lia - DF,
            doravante denominada "DESENVOLVEDORA".</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            2. {{ authenticatedUser.name }}, pessoa jur&iacute;dica de
            direito privado, inscrita no CNPJ sob n&ordm;
            {{ formatDocument(authenticatedUser.document) }} Rua
            {{ authenticatedUser.address?.street }}, nº
            {{ authenticatedUser.address?.number }}, bairro
            {{ authenticatedUser.address?.neighborhood }}. Cidade
            {{ authenticatedUser.address?.city }} -
            {{ authenticatedUser.address?.state }}. CEP
            {{ authenticatedUser.address?.postal_code }}, doravante
            denominada "GATEWAY".</span
          >
        </p>
        <p>
          Seller<span style="font-weight: 400"
            >: Pessoa ou empresa que realiza transa&ccedil;&otilde;es
            comerciais e est&aacute; sujeita &agrave; inclus&atilde;o na </span
          ><span style="font-weight: 400">lista restritiva </span
          ><span style="font-weight: 400">atrav&eacute;s da </span
          ><strong>GATEWAY</strong
          ><span style="font-weight: 400"
            >, de acordo com as regras estabelecidas no contrato</span
          ><span style="font-weight: 400">.</span>
        </p>
        <p>
          <span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span>
          <strong>CL&Aacute;USULA PRIMEIRA - OBJETO</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            O presente contrato tem como objeto a ades&atilde;o do GATEWAY
            ao sistema desenvolvido pela DESENVOLVEDORA, que permite o
            bloqueio de CNPJs e CPFs, atrav&eacute;s da
            inser&ccedil;&atilde;o e consulta de uma </span
          ><span style="font-weight: 400">lista restritiva.</span>
        </p>
        <p>
          <span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><strong
            >CL&Aacute;USULA SEGUNDA - REGRAS PARA INSER&Ccedil;&Atilde;O DE
            CNPJs E CPFs</strong
          ><strong><br /></strong><strong><br /></strong> <strong>1.</strong
          ><span style="font-weight: 400">
            A DESENVOLVEDORA e o GATEWAY reconhecem que o tratamento de
            dados pessoais (CNPJs e CPFs) deve estar em conformidade com a
            Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD). A base
            legal para o tratamento &eacute; o leg&iacute;timo interesse,
            conforme Art. 7&ordm;, inciso IX, da LGPD, visando a
            prote&ccedil;&atilde;o contra fraudes e golpes.</span
          >
        </p>
        <ol start="2">
          <li>
            <span style="font-weight: 400">
              O GATEWAY poder&aacute; inserir CNPJs e CPFs na </span
            ><span style="font-weight: 400">lista restritiva</span
            ><span style="font-weight: 400"
              >, de acordo com a an&aacute;lise interna do pr&oacute;prio
              GATEWAY, </span
            ><span style="font-weight: 400"
              >agindo sempre de boa-f&eacute; e com justificativa
              razo&aacute;vel.</span
            ><span style="font-weight: 400"><br /></span
            ><span style="font-weight: 400"
              >3. A inser&ccedil;&atilde;o de dados dever&aacute; ocorrer
              ap&oacute;s criteriosa an&aacute;lise, com o objetivo de
              evitar fraudes e golpes, </span
            ><span style="font-weight: 400"
              >garantindo que os dados inseridos sejam precisos e
              necess&aacute;rios.</span
            ><span style="font-weight: 400"><br /></span
            ><span style="font-weight: 400"
              >4. Inser&ccedil;&atilde;o maliciosa &eacute; considerada toda
              e qualquer inclus&atilde;o de CNPJ ou CPF que seja realizada
              com dolo, m&aacute;-f&eacute;, ou com o intuito de prejudicar
              terceiros sem justificativa razo&aacute;vel,
              comprovadamente.</span
            ><span style="font-weight: 400"><br /></span
            ><span style="font-weight: 400"><br /><br /></span>
          </li>
        </ol>
        <p>
          <strong
            >CL&Aacute;USULA TERCEIRA - CONSEQU&Ecirc;NCIAS DA
            INSER&Ccedil;&Atilde;O MALICIOSA</strong
          ><strong><br /></strong><strong><br /></strong>
          <span style="font-weight: 400"
            >1. Caso seja comprovado que o GATEWAY inseriu dados de forma
            maliciosa, com o intuito de prejudicar um seller, a
            DESENVOLVEDORA poder&aacute; aplicar as seguintes
            penalidades:</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            a. Bloqueio cadastral do GATEWAY no sistema.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            b. Aplica&ccedil;&atilde;o de multa no valor de R$ 20.000,00
            (vinte mil reais) a ser paga pelo GATEWAY &agrave;
            DESENVOLVEDORA.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            c. Responsabiliza&ccedil;&atilde;o jur&iacute;dica do GATEWAY,
            incluindo responsabilidade civil e, se aplic&aacute;vel,
            criminal, por quaisquer danos causados ao seller ou a
            terceiros.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /><br /></span>
        </p>
        <p>
          <strong
            >CL&Aacute;USULA QUARTA - ANONIMATO DOS DADOS INSERIDOS</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. As informa&ccedil;&otilde;es sobre qual GATEWAY inseriu os
            dados de um seller ser&atilde;o mantidas an&ocirc;nimas na
            plataforma, sendo utilizadas apenas para an&aacute;lise da
            confiabilidade do sistema.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            2. O GATEWAY poder&aacute;, a seu crit&eacute;rio, fornecer uma
            explica&ccedil;&atilde;o sobre a inser&ccedil;&atilde;o dos
            dados, o que poder&aacute; contribuir para a melhoria do
            indicativo de confiabilidade.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /><br /></span>
        </p>
        <p>
          <strong
            >CL&Aacute;USULA QUINTA - OBRIGA&Ccedil;&Otilde;ES DO
            GATEWAY</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. O GATEWAY dever&aacute; inserir os CNPJs e CPFs bloqueados em
            seus sistemas no sistema desenvolvido pela DESENVOLVEDORA.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"> 2. Caso o GATEWAY </span
          ><span style="font-weight: 400"
            >n&atilde;o atualize o banco de dados de CNPJ ou CPF no
            prazo</span
          ><span style="font-weight: 400">
            de 60 dias, o acesso ao sistema ser&aacute; automaticamente
            bloqueado.</span
          ><span style="font-weight: 400"><br /><br /></span>
        </p>
        <p>
          <span style="font-weight: 400"><br /></span
          ><strong
            >CL&Aacute;USULA SEXTA - SEGURAN&Ccedil;A E CONFIDENCIALIDADE
            DOS DADOS</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. A DESENVOLVEDORA declara que o sistema est&aacute; em total
            conformidade com a Lei Geral de Prote&ccedil;&atilde;o de Dados
            (LGPD), garantindo que todas as informa&ccedil;&otilde;es
            inseridas sejam criptografadas e armazenadas de forma segura, </span
          ><span style="font-weight: 400"
            >em conformidade com as melhores pr&aacute;ticas de
            seguran&ccedil;a da informa&ccedil;&atilde;o.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span>
          <span style="font-weight: 400"
            >2. Os dados e informa&ccedil;&otilde;es contidos no sistema
            n&atilde;o poder&atilde;o, sob nenhuma circunst&acirc;ncia, ser
            comercializados ou revendidos. Em caso de viola&ccedil;&atilde;o
            desta cl&aacute;usula, ser&aacute; aplicada uma multa severa no
            valor de R$ 20.000,00 (vinte mil reais) ao GATEWAY infrator,
            al&eacute;m de outras san&ccedil;&otilde;es
            cab&iacute;veis.</span
          ><span style="font-weight: 400"><br /><br /></span>
        </p>
        <p>
          <span style="font-weight: 400"><br /></span>
          <strong
            >CL&Aacute;USULA S&Eacute;TIMA - VIG&Ecirc;NCIA E
            RESCIS&Atilde;O</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. O presente contrato entra em vigor na data de sua assinatura
            e ter&aacute; vig&ecirc;ncia por prazo indeterminado.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            2. A DESENVOLVEDORA poder&aacute; rescindir este contrato de
            forma unilateral, a qualquer momento, mediante
            notifica&ccedil;&atilde;o por escrito ao GATEWAY, caso
            identifique qualquer viola&ccedil;&atilde;o das
            obriga&ccedil;&otilde;es aqui estabelecidas.</span
          >
        </p>
        <p>
          <span style="font-weight: 400"><br /></span>
          <strong
            >CL&Aacute;USULA OITAVA - DISPOSI&Ccedil;&Otilde;ES
            FINAIS</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. As partes elegem o foro da Comarca de &Aacute;guas Claras,
            Bras&iacute;lia-DF, para dirimir quaisquer d&uacute;vidas ou
            controv&eacute;rsias decorrentes deste contrato, com
            ren&uacute;ncia expressa a qualquer outro, por mais privilegiado
            que seja.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            2. O presente contrato constitui o entendimento completo entre
            as partes e substitui quaisquer outros acordos, entendimentos e
            negocia&ccedil;&otilde;es anteriores.</span
          >
        </p>
        <p>
          <span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span>
          <strong
            >CL&Aacute;USULA NONA - PROCESSO DE
            CONTESTA&Ccedil;&Atilde;O</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. O seller que se considerar indevidamente inclu&iacute;do na
            lista restritiva poder&aacute; apresentar uma
            contesta&ccedil;&atilde;o por escrito ao GATEWAY
            respons&aacute;vel pela inclus&atilde;o, que dever&aacute;
            responder no prazo de 15 dias &uacute;teis.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            2. Caso o seller n&atilde;o obtenha resposta ou a resposta seja
            insatisfat&oacute;ria, poder&aacute; requerer &agrave;
            DESENVOLVEDORA uma revis&atilde;o da inclus&atilde;o, que
            ser&aacute; conduzida de forma confidencial e imparcial.</span
          >
        </p>
        <p>
          <span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA - USO INDEVIDO DO SISTEMA</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. O GATEWAY compromete-se a utilizar o sistema exclusivamente
            para as finalidades previstas neste contrato, sendo vedado
            qualquer uso que extrapole ou desvirtue os objetivos de bloqueio </span
          ><span style="font-weight: 400"
            >de clientes que tem inten&ccedil;&atilde;o de uso de
            m&aacute;-f&eacute;.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            2. O uso indevido do sistema poder&aacute; acarretar na
            rescis&atilde;o imediata deste contrato, al&eacute;m da
            aplica&ccedil;&atilde;o de san&ccedil;&otilde;es, incluindo, mas
            n&atilde;o se limitando, a bloqueio de acesso, multas e
            responsabiliza&ccedil;&atilde;o jur&iacute;dica.</span
          >
        </p>
        <p>
          <span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA PRIMEIRA - AUDITORIA E
            SEGURAN&Ccedil;A DOS DADOS</strong
          ><strong><br /></strong><strong><br /></strong
          ><span style="font-weight: 400">
            1. A DESENVOLVEDORA se reserva o direito de realizar auditorias
            peri&oacute;dicas, a qualquer momento e sem aviso pr&eacute;vio,
            para verificar a conformidade do uso do sistema pelo GATEWAY com
            as disposi&ccedil;&otilde;es deste contrato.</span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400"><br /></span
          ><span style="font-weight: 400">
            2. O GATEWAY compromete-se a colaborar plenamente com as
            auditorias, fornecendo todas as informa&ccedil;&otilde;es e
            documentos necess&aacute;rios.</span
          >
        </p>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA SEGUNDA &ndash;
            INDENIZA&Ccedil;&Atilde;O</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY concorda em indenizar e isentar a DESENVOLVEDORA de
              qualquer responsabilidade, incluindo custos legais, despesas e
              danos, decorrentes de reclama&ccedil;&otilde;es,
              a&ccedil;&otilde;es judiciais, ou processos relacionados a
              dados incorretos ou maliciosamente inseridos pelo GATEWAY na
              lista restritiva.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              Esta obriga&ccedil;&atilde;o de indeniza&ccedil;&atilde;o
              incluir&aacute;, sem limita&ccedil;&atilde;o, quaisquer
              penalidades, multas ou despesas impostas por autoridades
              reguladoras devido a viola&ccedil;&atilde;o da LGPD por parte
              do GATEWAY.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA TERCEIRA - CONFIDENCIALIDADE E
            N&Atilde;O DIVULGA&Ccedil;&Atilde;O</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY compromete-se a manter em estrita confidencialidade
              todas as informa&ccedil;&otilde;es relacionadas ao sistema e
              aos dados tratados, n&atilde;o divulgando ou permitindo acesso
              a terceiros n&atilde;o autorizados.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              Qualquer viola&ccedil;&atilde;o &agrave;
              obriga&ccedil;&atilde;o de confidencialidade por parte do
              GATEWAY resultar&aacute; em penalidades adicionais e
              poder&aacute; ser motivo para rescis&atilde;o imediata do
              contrato, al&eacute;m de responsabiliza&ccedil;&atilde;o por
              danos.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA QUARTA - REVIS&Atilde;O E
            ATUALIZA&Ccedil;&Atilde;O DO SISTEMA</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA reserva-se o direito de revisar e atualizar o
              sistema e suas pol&iacute;ticas de prote&ccedil;&atilde;o de
              dados periodicamente para garantir a conformidade
              cont&iacute;nua com a LGPD e outras legisla&ccedil;&otilde;es
              aplic&aacute;veis.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              O GATEWAY ser&aacute; notificado sobre qualquer
              altera&ccedil;&atilde;o relevante e compromete-se a adaptar
              suas pr&aacute;ticas para refletir tais
              atualiza&ccedil;&otilde;es.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA QUINTA - REVERS&Atilde;O DE DADOS
            E EXCLUS&Atilde;O</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              Em caso de rescis&atilde;o ou t&eacute;rmino do contrato, o
              GATEWAY deve assegurar que todos os dados pessoais, incluindo
              aqueles inseridos, sejam exclu&iacute;dos ou revertidos
              conforme solicitado pela DESENVOLVEDORA, salvo se a
              reten&ccedil;&atilde;o for necess&aacute;ria para cumprir
              obriga&ccedil;&otilde;es legais.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA se reserva o direito de realizar auditorias
              para verificar a conformidade com a exclus&atilde;o dos dados
              ap&oacute;s a rescis&atilde;o do contrato.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA SEXTA - RESPONSABILIDADE PELO
            CONSENTIMENTO</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY &eacute; respons&aacute;vel por garantir que obteve
              o consentimento adequado dos titulares dos dados, quando
              necess&aacute;rio, para o tratamento dos CNPJs e CPFs, e que
              este consentimento est&aacute; documentado e acess&iacute;vel
              para verifica&ccedil;&atilde;o. O GATEWAY deve tomar todas as
              medidas necess&aacute;rias para garantir a precis&atilde;o e
              integridade das informa&ccedil;&otilde;es fornecidas e
              inseridas no sistema.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <ol start="2">
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA n&atilde;o ser&aacute; respons&aacute;vel por
              qualquer falha do GATEWAY em obter ou gerenciar o
              consentimento dos titulares dos dados, assim como
              informa&ccedil;&otilde;es incorretas ou incompletas fornecidas
              pelo GATEWAY.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA S&Eacute;TIMA - COMPLIANCE COM
            NORMAS DE SEGURAN&Ccedil;A</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY deve cumprir com todas as normas e
              regulamenta&ccedil;&otilde;es aplic&aacute;veis de
              seguran&ccedil;a da informa&ccedil;&atilde;o e
              prote&ccedil;&atilde;o de dados, incluindo, mas n&atilde;o se
              limitando a, padr&otilde;es da ISO 27001 e outras normas
              relevantes.</span
            >
          </li>
        </ol>
        <p>
          <span style="font-weight: 400"><br /><br /></span>
        </p>
        <ol start="2">
          <li>
            <span style="font-weight: 400">
              O n&atilde;o cumprimento dessas normas poder&aacute; resultar
              em penalidades e a&ccedil;&otilde;es corretivas, incluindo a
              possibilidade de rescis&atilde;o do contrato.</span
            >
          </li>
        </ol>
        <p>
          <span style="font-weight: 400"><br /></span
          ><strong
            >CL&Aacute;USULA D&Eacute;CIMA OITAVA - AVALIA&Ccedil;&Atilde;O
            DE IMPACTO DE PROTE&Ccedil;&Atilde;O DE DADOS</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY concorda em realizar avalia&ccedil;&otilde;es de
              impacto de prote&ccedil;&atilde;o de dados (DPIAs) sempre que
              necess&aacute;rio e sempre que houver mudan&ccedil;as
              significativas no tratamento de dados pessoais.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA poder&aacute; solicitar c&oacute;pias dessas
              avalia&ccedil;&otilde;es para garantir a conformidade com a
              LGPD.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA D&Eacute;CIMA NONA - NOTIFICA&Ccedil;&Atilde;O
            DE INCIDENTES DE SEGURAN&Ccedil;A</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY deve notificar imediatamente a DESENVOLVEDORA sobre
              qualquer incidente de seguran&ccedil;a que possa afetar os
              dados pessoais tratados no &acirc;mbito deste contrato,
              fornecendo informa&ccedil;&otilde;es sobre a natureza do
              incidente e as medidas adotadas para
              mitiga&ccedil;&atilde;o.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA ter&aacute; o direito de tomar as
              a&ccedil;&otilde;es necess&aacute;rias para proteger os dados
              e minimizar os danos resultantes do incidente.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA - REVIS&Atilde;O
            PERI&Oacute;DICA</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              As partes concordam em revisar este contrato periodicamente, a
              cada 12 meses ou conforme necess&aacute;rio, para garantir que
              continua a atender &agrave;s necessidades das partes e
              est&aacute; em conformidade com a legisla&ccedil;&atilde;o
              vigente.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA PRIMEIRA - RESPONSABILIDADE POR
            DADOS DE TERCEIROS</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              Caso o GATEWAY utilize dados de terceiros que n&atilde;o sejam
              diretamente relacionados ao contrato, o GATEWAY dever&aacute;
              garantir que tem todas as autoriza&ccedil;&otilde;es
              necess&aacute;rias e que esses dados tamb&eacute;m
              est&atilde;o em conformidade com a LGPD.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA n&atilde;o ser&aacute; respons&aacute;vel por
              qualquer n&atilde;o conformidade ou viola&ccedil;&atilde;o
              relacionada a dados de terceiros que n&atilde;o tenham sido
              devidamente autorizados ou gerenciados pelo GATEWAY.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA SEGUNDA -
            COOPERA&Ccedil;&Atilde;O EM INVESTIGA&Ccedil;&Otilde;ES</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              As partes se comprometem a cooperar plenamente em qualquer
              investiga&ccedil;&atilde;o ou auditoria conduzida por
              autoridades reguladoras ou outras entidades competentes
              relacionadas &agrave; prote&ccedil;&atilde;o de dados.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA ter&aacute; acesso a todas as
              informa&ccedil;&otilde;es relevantes e ao GATEWAY ser&aacute;
              exigida a fornecer total colabora&ccedil;&atilde;o.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA TERCEIRA - GEST&Atilde;O DE
            COOKIES E TECNOLOGIAS SEMELHANTES</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              Caso o sistema desenvolvido pela DESENVOLVEDORA utilize
              cookies ou outras tecnologias de rastreamento, o GATEWAY deve
              garantir que tais tecnologias estejam em conformidade com a
              LGPD e outras legisla&ccedil;&otilde;es relevantes.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              O GATEWAY deve fornecer informa&ccedil;&otilde;es claras aos
              titulares dos dados sobre o uso dessas tecnologias e obter o
              consentimento adequado quando necess&aacute;rio.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA QUARTA - TREINAMENTO E
            CONSCIENTIZA&Ccedil;&Atilde;O</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY concorda em fornecer treinamento regular a seus
              funcion&aacute;rios e colaboradores sobre
              prote&ccedil;&atilde;o de dados e seguran&ccedil;a da
              informa&ccedil;&atilde;o, para garantir que todos os
              envolvidos compreendam suas responsabilidades e
              obriga&ccedil;&otilde;es em rela&ccedil;&atilde;o &agrave;
              LGPD.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA poder&aacute; solicitar
              comprova&ccedil;&otilde;es de tais treinamentos.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA QUINTA - EXCLUS&Atilde;O DE
            DADOS AP&Oacute;S FINALIZA&Ccedil;&Atilde;O</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              Ap&oacute;s a finaliza&ccedil;&atilde;o deste contrato, seja
              por t&eacute;rmino ou rescis&atilde;o, o GATEWAY deve
              assegurar que todos os dados pessoais sejam exclu&iacute;dos
              ou anonimizados, conforme solicitado pela DESENVOLVEDORA,
              salvo se houver exig&ecirc;ncia legal para
              reten&ccedil;&atilde;o dos dados.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA ter&aacute; o direito de solicitar
              confirma&ccedil;&otilde;es de exclus&atilde;o e realizar
              auditorias para garantir a conformidade.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA SEXTA - PROTE&Ccedil;&Atilde;O
            CONTRA ACESSO N&Atilde;O AUTORIZADO</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY deve implementar medidas de seguran&ccedil;a
              adequadas para proteger o sistema contra acesso n&atilde;o
              autorizado, incluindo o uso de autentica&ccedil;&atilde;o
              forte, criptografia e controles de acesso.</span
            ><span style="font-weight: 400"><br /><br /></span>
          </li>
          <li>
            <span style="font-weight: 400">
              A DESENVOLVEDORA deve ser notificada imediatamente sobre
              qualquer suspeita de acesso n&atilde;o autorizado ou
              viola&ccedil;&atilde;o de seguran&ccedil;a.</span
            >
          </li>
        </ol>
        <p class="demoTitle">&nbsp;</p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA S&Eacute;TIMA -
            RESPONSABILIDADE POR ERROS E OMISS&Otilde;ES</strong
          >
        </p>
        <p>
          <span style="font-weight: 400"
            >A DESENVOLVEDORA n&atilde;o ser&aacute; respons&aacute;vel por
            erros ou omiss&otilde;es no sistema que possam ocorrer devido a
            informa&ccedil;&otilde;es incorretas ou incompletas fornecidas
            pelo GATEWAY.</span
          >
        </p>
        <p>
          <strong
            >CL&Aacute;USULA VIG&Eacute;SIMA OITAVA- CONSENTIMENTO PARA
            COMPARTILHAMENTO DE DADOS</strong
          >
        </p>
        <ol>
          <li>
            <span style="font-weight: 400">
              O GATEWAY se compromete a incluir em seus pr&oacute;prios
              termos e condi&ccedil;&otilde;es, bem como nas
              pol&iacute;ticas de privacidade, uma cl&aacute;usula clara e
              expl&iacute;cita solicitando o consentimento dos titulares dos
              dados para o compartilhamento de CNPJs e CPFs com a
              DESENVOLVEDORA.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              O GATEWAY deve garantir que o consentimento seja obtido de
              forma adequada, conforme as exig&ecirc;ncias da Lei Geral de
              Prote&ccedil;&atilde;o de Dados (LGPD), e que o mesmo esteja
              documentado e acess&iacute;vel para auditoria pela
              DESENVOLVEDORA, quando solicitado.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              O GATEWAY &eacute; respons&aacute;vel por assegurar que os
              titulares dos dados tenham acesso &agrave;s
              informa&ccedil;&otilde;es necess&aacute;rias sobre o
              compartilhamento e tratamento de seus dados, e que possam
              exercer seus direitos previstos na LGPD.</span
            >
          </li>
          <li>
            <span style="font-weight: 400">
              O GATEWAY deve fornecer comprova&ccedil;&atilde;o do
              consentimento obtido, bem como a documenta&ccedil;&atilde;o
              relacionada, &agrave; DESENVOLVEDORA, sempre que
              solicitado.</span
            >
          </li>
        </ol>
        <p class="demoTitle"><br /><br /><br /></p>
        <!-- Comments are visible in the HTML source only -->
      </div>
    </div>
    <div class="modal-footer">
      <button @click="$emit('onAccept')">Sim concordo</button>
      <button @click="$emit('onRefuse')">Não, concordo</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useFormat } from '@/composables/useFormat'

const { formatDocument } = useFormat()
defineProps<{ authenticatedUser: any }>()
const emit = defineEmits(['onAccept', 'onRefuse'])
</script>