<template>
  <div
    class="font-inter flex flex-col gap-8 companies query-customer pt-12 sm:pt-6"
  >
    <div>
      <h1 class="text-3xl font-bold pb-2 text-292 font-nunito">
        Consultar cliente
      </h1>
      <p class="text-gray-500 text-sm">Consulte pelo CPF ou CNPJ do cliente</p>
    </div>
    <div class="grid grid-cols-12 query-customer__filter gap-6">
      <div class="query-customer__filter__search flex col-span-12">
        <form @submit.prevent class="rounded-xl w-full relative">
          <input
            type="tel"
            placeholder="Digite um CPF ou CNPJ"
            class="rounded-tl-xl rounded-bl-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11 border"
            @keydown.enter="getCustomer"
            v-model="search"
            v-maska="
              search.length <= 14 ? '###.###.###-###' : '##.###.###/####-##'
            "
          />
          <svg
            class="absolute top-1/2 -translate-y-1/2 left-4"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z"
              fill="#676767"
            />
          </svg>
        </form>
        <button
          @click="getCustomer"
          class="rounded-tr-lg rounded-br-lg font-inter text-white font-bold"
        >
          pesquisar
        </button>
      </div>
    </div>
    <section>
      <div
        v-if="percentage !== undefined && !notFound"
        class="flex flex-col items-center gap-4 justify-center"
      >
        <CircleProgress :percent="percentage" :show-percent="true">
          100%
        </CircleProgress>
        <!-- <button @click="isCreateBlockingReasonModalOpen = true" class="border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add mx-auto">
                Adicionar
            </button> -->
      </div>
      <div
        class="flex items-center justify-center flex-col gap-2"
        v-if="
          (percentage == undefined && !isLoading) || (notFound && !isLoading)
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="104"
          height="104"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C9.66 2.75 7.75 4.66 7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75Z"
            fill="#171717"
          />
          <path
            d="M3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22C2.66016 17.73 6.85018 14.25 12.0002 14.25C12.4102 14.25 12.7502 14.59 12.7502 15C12.7502 15.41 12.4102 15.75 12.0002 15.75C7.68018 15.75 4.16016 18.55 4.16016 22C4.16016 22.41 3.82016 22.75 3.41016 22.75Z"
            fill="#171717"
          />
          <path
            d="M18.2 22.15C16.02 22.15 14.25 20.38 14.25 18.2C14.25 16.02 16.02 14.25 18.2 14.25C20.38 14.25 22.15 16.02 22.15 18.2C22.15 20.38 20.38 22.15 18.2 22.15ZM18.2 15.75C16.85 15.75 15.75 16.85 15.75 18.2C15.75 19.55 16.85 20.65 18.2 20.65C19.55 20.65 20.65 19.55 20.65 18.2C20.65 16.85 19.55 15.75 18.2 15.75Z"
            fill="#171717"
          />
          <path
            d="M22 22.75C21.81 22.75 21.6199 22.68 21.4699 22.53L20.4699 21.53C20.1799 21.24 20.1799 20.7599 20.4699 20.4699C20.7599 20.1799 21.24 20.1799 21.53 20.4699L22.53 21.4699C22.82 21.7599 22.82 22.24 22.53 22.53C22.38 22.68 22.19 22.75 22 22.75Z"
            fill="#171717"
          />
        </svg>
        <p class="font-bold" v-if="!notFound">
          Digite e aperte enter para fazer uma busca
        </p>
        <div class="flex flex-col" v-else>
          <p class="text-yellow-500 pb-2">Esta empresa não está cadastrada</p>
          <RouterLink
            :to="{ name: 'customers.create', query: { document: search } }"
            class="border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add mx-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#171717"
                class="stroke-emerald-600"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 12H16"
                stroke="#171717"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="stroke-emerald-600"
              />
              <path
                d="M12 16V8"
                stroke="#171717"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="stroke-emerald-600"
              />
            </svg>
            Adicionar
          </RouterLink>
        </div>
      </div>
      <SpinnerLoader v-if="isLoading" />
    </section>

    <!-- <MyModal :isOpen="isCreateBlockingReasonModalOpen" :toggleModal="(v: boolean) => isCreateBlockingReasonModalOpen = v" :width="'40rem'" :height="'37rem'" :id="'create-blocking-reason-modal'">
        <div class="p-8">
            <div class="flex items-center font-inter justify-between mb-4">
                <h2 class="text-2xl font-bold">Adicione um motivo de bloqueio</h2>
                <button @click="isCreateBlockingReasonModalOpen = false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="#090909" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="#090909" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div class="flex flex-col gap-4 py-4">
                <div class="font-inter flex flex-col gap-2 col-span-6">
                    <label class="font-bold text-lg">Motivo</label>
                    <p class="text-zinc-500">Adicione o motivo pelo qual você está denunciando essa empresa</p>
                    <textarea v-model="reportPayload.reason" type="text" placeholder="ex: Esta empresa não passou no compliance" ></textarea>
                </div>
            </div>
            <div class="font-inter flex justify-between pt-8 submit-wrapper">
                <button class="bg-emerald-600 font-bold text-white px-12 py-3 rounded-3xl" @click="reportCustomer" :disabled="!reportPayload.reason" v-if="!isReportRequestLoading">Enviar</button>
                <SpinnerLoader v-else />
                <button @click="isCreateBlockingReasonModalOpen = false">Cancelar</button>
            </div>
        </div>
    </MyModal> -->
  </div>
</template>

<script lang="ts" setup>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { ref } from "vue";
import axiosClient from "@/api/axios";
import { vMaska } from "maska/vue";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import MyModal from "@/components/modal/MyModal.vue";
import { toast } from "vue3-toastify";

const search = ref("");
const percentage = ref();
const isLoading = ref(false);
const notFound = ref(false);
const isCreateBlockingReasonModalOpen = ref(false);
const reportPayload = ref<Record<string, any>>({});
const isReportRequestLoading = ref(false);

function getCustomer() {
  isLoading.value = true;

  axiosClient
    .get(`/customers/${search.value.replace(/[-\/.]/g, "")}`)
    .then((res) => {
      percentage.value = res.data.percentage;
      notFound.value = false;
    })
    .catch(() => {
      notFound.value = true;
    })
    .finally(() => (isLoading.value = false));
}

function reportCustomer() {
  isReportRequestLoading.value = true;

  axiosClient
    .post("/companies/report", {
      reason: reportPayload.value.reason,
      document: search.value.replace(/[\-\.\//]/g, ""),
    })
    .then(() => {
      reportPayload.value = {};
      isCreateBlockingReasonModalOpen.value = false;
      toast.success("Denúncia cadastrada com sucesso");
    })
    .finally(() => (isReportRequestLoading.value = false));
}
</script>

<style lang="scss" scoped>
.query-customer {
  input[type="tel"] {
    height: 3.4375rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px 15px 40px;
    outline: unset;
    color: $primary-color;
    font-family: "Inter", sans-serif;
    &::placeholder {
      color: #484848;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }
  textarea {
    border-radius: 0.875rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px;
    outline: unset;
    height: 17rem;
  }
  input:disabled,
  button:disabled {
    background: #f1f1f1 !important;
    color: #6f6f6f !important;
    cursor: not-allowed !important;
  }
  &__filter {
    &__search {
      button {
        background: #059669;
        padding: 1rem;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .companies {
      &__filter {
        grid-template-columns: 1fr;
      }
      #td-contact {
        padding-bottom: 1rem;
      }
    }
  }

  .add {
    transition: all 0.2s ease;
    path {
      transition: all 0.2s ease;
    }
    &:hover {
      background: #059669;
      color: #fff;
      path {
        stroke: #fff;
      }
    }
  }
}
</style>
