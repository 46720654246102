<template>
<div class="font-inter flex flex-col gap-8 companies query-company pt-12 sm:pt-6">
    <div>
        <h1 class="text-3xl font-bold text-292 font-nunito">Consultar empresa</h1>
        <p class="text-gray-500 text-sm">Consulte pelo CPF ou CNPJ da empresa</p>
    </div>
    <div class="grid grid-cols-12 query-company__filter gap-6">
        <div class="query-company__filter__search flex col-span-12">
            <form @submit.prevent class="rounded-xl w-full relative">
                <input type="tel" placeholder="Digite um CPF ou CNPJ" class="rounded-tl-xl rounded-bl-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11 border" @keydown.enter="getCompany" v-model="search" @blur="search.length !== 64 ? toast.error('Por favor, criptografe o número do documento utilizando a criptografia sha256') : ''">
                <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z" fill="#676767"/>
                </svg>
            </form>
            <button @click="getCompany" class="rounded-tr-lg rounded-br-lg font-inter text-white font-bold" :disabled="search.length !== 64">
                pesquisar
            </button>
        </div>
        <!-- <div class="col-span-1 flex gap-4">
            <RouterLink :to="{ name: 'companies.query' }" class="border p-4 flex rounded-xl">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M14 5H20" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14 8H17" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 11.5C21 16.75 16.75 21 11.5 21C6.25 21 2 16.75 2 11.5C2 6.25 6.25 2 11.5 2" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 22L20 20" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </RouterLink>
            <RouterLink :to="{ name: 'companies.create' }" class="border p-4 flex rounded-xl">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </RouterLink>
        </div> -->
    </div>
    <section>
        <div v-if="percentage !== undefined && !notFound" class="flex flex-col items-center gap-4 justify-center">
            <CircleProgress :percent="percentage" :showPercent="true" />
            <p class="text-orange-500 flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path class="stroke-orange-500" d="M12 7.75V13" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path class="stroke-orange-500" d="M21.08 8.58003V15.42C21.08 16.54 20.4799 17.58 19.5099 18.15L13.5699 21.58C12.5999 22.14 11.3999 22.14 10.4199 21.58L4.47992 18.15C3.50992 17.59 2.90991 16.55 2.90991 15.42V8.58003C2.90991 7.46003 3.50992 6.41999 4.47992 5.84999L10.4199 2.42C11.3899 1.86 12.5899 1.86 13.5699 2.42L19.5099 5.84999C20.4799 6.41999 21.08 7.45003 21.08 8.58003Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 16.2V16.2999" class="stroke-orange-500" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                Cadastro com risco de aprovação
            </p>
            <button @click="isCreateBlockingReasonModalOpen = true" class="border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add mx-auto">
                Adicionar Motivo de Recusa
            </button>
        </div>
        <div class="flex items-center justify-center flex-col gap-2" v-if="(percentage == undefined && !isLoading) || (notFound && !isLoading)">
            <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 24 24" fill="none">
                <path d="M20.5902 10.5501V7.12006C20.5902 5.89006 19.6502 4.53006 18.5002 4.10006L13.5102 2.23006C12.6802 1.92006 11.3202 1.92006 10.4902 2.23006L5.50016 4.11006C4.35016 4.54006 3.41016 5.90006 3.41016 7.12006V14.5501C3.41016 15.7301 4.19016 17.2801 5.14016 17.9901L9.44016 21.2001C10.1402 21.7401 11.0702 22.0001 12.0002 22.0001" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.9955 21H21.0045" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p class="font-bold" v-if="!notFound">Digite e aperte enter para fazer uma busca</p>
            <div class="flex flex-col" v-else>
                <p class="text-yellow-500 pb-2">Esta empresa não está cadastrada</p>
                <RouterLink :to="{ name: 'companies.create', query: { document: search } }" class="border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#171717" class="stroke-emerald-600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 12H16" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-emerald-600" />
                        <path d="M12 16V8" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-emerald-600" />
                    </svg>
                    Adicionar
                </RouterLink>
            </div>
        </div>
        <SpinnerLoader v-if="isLoading" />
    </section>

    <MyModal :isOpen="isCreateBlockingReasonModalOpen" :toggleModal="(v: boolean) => isCreateBlockingReasonModalOpen = v" :width="'40rem'" :height="'37rem'" :id="'create-blocking-reason-modal'">
        <div class="p-8">
            <div class="flex items-center font-inter justify-between mb-4">
                <h2 class="text-2xl font-bold">Adicione um motivo de bloqueio</h2>
                <button @click="isCreateBlockingReasonModalOpen = false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="#090909" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="#090909" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div class="flex flex-col gap-4 py-4">
                <div class="font-inter flex flex-col gap-2 col-span-6">
                    <label class="font-bold text-lg">Motivo</label>
                    <p class="text-zinc-500">Adicione o motivo pelo qual você está denunciando essa empresa</p>
                    <textarea v-model="reportPayload.reason" type="text" placeholder="ex: Esta empresa não passou no compliance" ></textarea>
                </div>
            </div>
            <div class="font-inter flex justify-between pt-8 submit-wrapper">
                <button class="bg-emerald-600 font-bold text-white px-12 py-3 rounded-3xl" @click="reportCompany" :disabled="!reportPayload.reason" v-if="!isReportRequestLoading">Enviar</button>
                <SpinnerLoader v-else />
                <button @click="isCreateBlockingReasonModalOpen = false">Cancelar</button>
            </div>
        </div>
    </MyModal>
</div>
</template>

<script lang="ts" setup>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { ref } from "vue";
import axiosClient from "@/api/axios";
import { vMaska } from "maska/vue"
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import MyModal from "@/components/modal/MyModal.vue";
import { toast } from 'vue3-toastify';

const search = ref('')
const percentage = ref()
const isLoading = ref(false)
const notFound = ref(false)
const isCreateBlockingReasonModalOpen = ref(false)
const reportPayload = ref<Record<string, any>>({})
const isReportRequestLoading = ref(false)

function getCompany(){
    isLoading.value = true

    axiosClient.get(`/companies/${search.value.replace(/[-\/.]/g, '')}`)
    .then((res) => {
        percentage.value = res.data.score
        notFound.value = false
    }).catch(() => {
        notFound.value = true
    }).finally(() => isLoading.value = false)
}

function reportCompany(){
    isReportRequestLoading.value = true

    axiosClient.post('/companies/report', { reason: reportPayload.value.reason, document: search.value.replace(/[\-\.\//]/g, '') })
    .then(() => {
        reportPayload.value = {}
        isCreateBlockingReasonModalOpen.value = false
        toast.success('Denúncia cadastrada com sucesso')
    }).finally(() => isReportRequestLoading.value = false)
}
</script>

<style lang="scss" scoped>
.query-company{
    input[type="tel"]{
        height:3.4375rem;
        border:1px solid var(--cinza-medio, #D3D3D3);
        padding:15px 20px 15px 40px;
        outline:unset;
        color: $primary-color;
        font-family: 'Inter', sans-serif;
        &::placeholder{
            color: #484848;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        &:focus{
            &::placeholder{
                color: transparent;
            }
        }
    }
    textarea{
        border-radius: 0.875rem;
        border:1px solid var(--cinza-medio, #D3D3D3);
        padding:15px 20px;
        outline:unset;
        height:17rem
    }
    input:disabled, button:disabled{
        background: #F1F1F1 !important;
        color: #6F6F6F !important;
        cursor: not-allowed !important;
    }
    &__filter{
        &__search{
            button{
                background: #059669;
                padding: 1rem
            }
        }
    }
    @media screen and (max-width:600px) {
        .companies{
            &__filter{
                grid-template-columns: 1fr;
            }
            #td-contact{
                padding-bottom:1rem
            }
        }
    }

    .add{
        transition: all .2s ease;
        path{
            transition: all .2s ease;
        }
        &:hover{
            background: #059669;
            color: #fff;
            path{
                stroke: #fff
            }
        }
    }
}
</style>