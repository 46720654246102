<template>
  <div class="credentials pt-12 sm:pt-6">
    <h1 class="text-3xl font-bold font-nunito">Credenciais</h1>
    <p class="text-gray-500 text-sm font-inter">Gerencie as credenciais de autorização da API</p>
    <a href="@/assets/documentation.json" download="documentation.json" class="inline-flex items-center gap-2 font-inter px-4 py-2 border rounded-xl my-2 mt-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M9.5 11V17L11.5 15" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 17L7.5 15" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.5 10V15C22.5 20 20.5 22 15.5 22H9.5C4.5 22 2.5 20 2.5 15V9C2.5 4 4.5 2 9.5 2H14.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.5 10H18.5C15.5 10 14.5 9 14.5 6V2L22.5 10Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      Baixar documentação
    </a>
    <section class="grid grid-cols-12 gap-4 mt-6">
      <div class="col-span-12 sm:col-span-6">
        <label for="client-id"><strong>Client id</strong></label>
        <input autocomplete='off' :value="credentials.clientId" type="text" id="client-id">
      </div>
      <div class="col-span-12 sm:col-span-6">
        <label for="client-secret"><strong>Client secret</strong></label>
        <input autocomplete='off' :value="credentials.clientSecret" type="text" id="client-secret">
      </div>
    </section>
    <button v-if="!credentials.clientId && !isGeneratingCredentials" @click="generateCredentials" id="genarate-credentials">Gerar credenciais</button>
    <div class="mt-4">
      <SpinnerLoaderButton v-if="isGeneratingCredentials" :height="60" :maxWidth="200" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import axiosClient from '@/api/axios'
import SpinnerLoaderButton from "@/components/loader/SpinnerLoaderButton.vue";

const credentials = ref({ clientId: '', clientSecret: '' })
const isGeneratingCredentials = ref(false)

function generateCredentials() {
  isGeneratingCredentials.value = true

  axiosClient.post('/accounts/developer/credentials')
    .then((res) => {
      credentials.value = res.data
    })
    .finally(() => isGeneratingCredentials.value = false)
}
</script>

<style lang="scss" scoped>
  #genarate-credentials {
    width: 200px;
    height: 60px;
    border: none;
    border-radius: 30px;
    background: $primary-color;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    margin: auto 0 0 0;
    transition: background .2s;
    margin-top: 1rem;
    cursor: pointer;
    &:hover{
      background: $primary-color-hover;
    }
    &:disabled{
      background: #F1F1F1 !important;
      color: #6F6F6F !important;
      cursor: not-allowed !important;
    }
  }
  label{
    color: #090909;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 13px 0;
    strong{
      font-size: 20px;
    }
  }
  input {
    width: 100%;
    height: 55px;
    padding: 0 18px;
    border-radius: 16px;
    border: none;
    background: #F5F5F5;
    box-sizing: border-box;
    color: #090909;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &::placeholder{
      color: #090909;
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }
    &:focus{
      outline: none;
      color: $primary-color;
      padding: 0 16px !important;
      &::placeholder{
        color: transparent;
      }
      & ~ button{
        svg{
          stroke: $primary-color !important;
        }
      }
    }
    &:read-only{
      cursor: default;
      color: $primary-color !important;
    }
  }
</style>