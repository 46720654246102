<template>
  <div class="font-inter p-4 sm:p-6">
    <h2 class="text-3xl font-bold text-center">Autenticação 2Fatores</h2>
    <p>1. Abra o app Authenticator adicione uma nova conta.</p>
    <p>2. Leia o QrCode exibido abaixo usando o Authenticator</p>
    <p>3. Digite o código de 6 dígitos do app.</p>
    <p>4. Pronto! A autenticação está configurada.</p>
    <img v-if="qrCode" class="mx-auto mt-6" :src="qrCode" />
    <div class="py-10 my-10 flex justify-center" v-else>
      <SpinnerLoader />
    </div>
    <input v-model="code" type="password" placeholder="Digite o código" class="my-6" />
    <button v-if="!isLoading" :disabled="code.length < 6" @click="confirmFirstCode">Verificar</button>
    <SpinnerLoaderButton v-else :height="60" :maxWidth="464" />
  </div>
</template>

<script lang="ts" setup>
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import axiosClient from '@/api/axios'
import { ref } from 'vue'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import { toast } from 'vue3-toastify';


const emit = defineEmits(['onFirstCodeConfirmation'])
defineProps<{ qrCode: string }>()

const code = ref('')
const isLoading = ref(false)

function confirmFirstCode() {
  isLoading.value = true

  axiosClient.put('/accounts/2af', { code: code.value.replaceAll(' ', '') })
    .then((res) => {
      if (res.data.verified) {
        const domain = '.' + process.env?.VUE_APP_DOMAIN;
        document.cookie = `authenticated_user=${encodeURIComponent(JSON.stringify(res.data.user))}; path=/; domain=${domain}; secure; SameSite=Lax`;
        document.cookie = `auth_token=${encodeURIComponent(res.data.access_token)}; path=/; domain=${domain}; secure; SameSite=Lax`;
        localStorage.setItem('hasValidated2AF', 'true')
        toast.success('Código validado')
        emit('onFirstCodeConfirmation')
      }
    })
    .catch(() => toast.error('Código inválido'))
    .finally(() => isLoading.value = false)
}
</script>

<style lang="scss" scoped>
input{
  width: 100%;
  height: 55px;
  padding: 0 18px;
  border-radius: 16px;
  border: none;
  background: #F5F5F5;
  box-sizing: border-box;
  color: #090909;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &::placeholder{
    color: #090909;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  &:focus{
    outline: none;
    color: $primary-color;
    padding: 0 16px !important;
    &::placeholder{
      color: transparent;
    }
    & ~ button{
      svg{
        stroke: $primary-color !important;
      }
    }
  }
  &:read-only{
    cursor: default;
    color: $primary-color !important;
  }
}
button {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  background: $primary-color;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  margin: auto 0 0 0;
  transition: background .2s;
  cursor: pointer;
  &:hover{
    background: $primary-color-hover;
  }
  &:disabled{
    background: #F1F1F1 !important;
    color: #6F6F6F !important;
    cursor: not-allowed !important;
  }
}
</style>