<template>
  <div class="show-company">
    <div class="flex">
      <RouterLink
        :to="{ name: 'companies' }"
        class="flex items-center gap-2 mb-8 go-back"
      >
        <div class="bg-zinc-100 p-3 rounded-full go-back__icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67"
              stroke="#090909"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h1 class="text-3xl font-bold font-nunito">Nome da empresa</h1>
      </RouterLink>
    </div>
    <!-- <ul class="font-inter flex mb-8">
        <li class="flex justify-center items-center gap-2 bg-zinc-100 p-4 py-2 rounded-3xl">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16.4405 8.90039C20.0405 9.21039 21.5105 11.0604 21.5105 15.1104V15.2404C21.5105 19.7104 19.7205 21.5004 15.2505 21.5004H8.74047C4.27047 21.5004 2.48047 19.7104 2.48047 15.2404V15.1104C2.48047 11.0904 3.93047 9.24039 7.47047 8.91039" stroke="#090909" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 2V14.88" stroke="#090909" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.3484 12.6504L11.9984 16.0004L8.64844 12.6504" stroke="#090909" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p>Baixar</p>
        </li>
    </ul> -->

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-6 flex flex-col gap-4">
        <section class="flex flex-col gap-4 grid grid-cols-12">
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg" for="">Razão social</label>
            <input type="text" placeholder="ex: Empresa Pay" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg" for="">Nome Fantasia</label>
            <input type="text" placeholder="ex: Empresa Pay" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg" for="">CPF/CNPJ</label>
            <input type="text" placeholder="ex: 000.000.000-00" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg" for="">Email</label>
            <input type="text" placeholder="ex: email@gmail.com" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg" for="">Total de aderentes</label>
            <input type="text" placeholder="ex: 10" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg" for="">Total pendentes</label>
            <input type="text" placeholder="ex: 8" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-12">
            <label class="font-bold text-lg" for="">Data de cadastro</label>
            <input type="text" placeholder="ex: 07/08/2021" />
          </div>
        </section>
        <!-- <section class="flex flex-col gap-2">
                <h2 class="font-inter font-bold text-xl">Dados bancários</h2>
                <div class="border rounded-xl p-4 font-inter">
                    <p><strong>Agência:</strong> nome da agência</p>
                    <p><strong>Titular:</strong> nome da agência</p>
                    <p><strong>Agência:</strong> nome da agência</p>
                    <p><strong>Conta:</strong> nome da agência</p>
                </div>
            </section>
            <section class="flex flex-col gap-2">
                <div class="flex justify-between font-inter items-center">
                    <h2 class="font-inter font-bold text-xl">Sócios</h2>
                    <button class="border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add" @click="isEditPartnersModalOpen = true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#171717" class="stroke-emerald-600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 12H16" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-emerald-600" />
                            <path d="M12 16V8" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-emerald-600" />
                        </svg>
                        Adicionar
                    </button>
                </div>
                <div class="border rounded-xl p-4 font-inter">
                    <p><strong>Nome:</strong> nome da agência</p>
                    <p><strong>Documento:</strong> nome da agência</p>
                    <p><strong>Data de nascimento:</strong> nome da agência</p>
                    <p><strong>Porcentagem:</strong> nome da agência</p>
                </div>
            </section> -->
      </div>
      <section class="col-span-6 flex flex-col gap-2">
        <section class="flex flex-col gap-2">
          <div class="flex justify-between font-inter items-center">
            <h2 class="font-inter font-bold text-xl">Motivo do bloqueio</h2>
            <button
              class="border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add"
              @click="isCreateBlockingReasonModalOpen = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#171717"
                  class="stroke-emerald-600"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 12H16"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-emerald-600"
                />
                <path
                  d="M12 16V8"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-emerald-600"
                />
              </svg>
              Adicionar
            </button>
          </div>
          <!-- <div>
                    <div class="border rounded-xl p-4 font-inter">
                        <p><strong>Motivo:</strong> fulano teve prejuízo de R$ 30.000,00</p>
                        <p><strong>Log criado em:</strong> 20/10/2008</p>
                    </div>
                </div> -->
        </section>
      </section>
    </div>

    <MyModal
      :isOpen="isCreateBlockingReasonModalOpen"
      :toggleModal="(v) => (isCreateBlockingReasonModalOpen = v)"
      :width="'40rem'"
      :height="'33rem'"
      :id="'create-blocking-reason-modal'"
    >
      <div class="p-8">
        <div class="flex items-center font-inter justify-between mb-4">
          <h2 class="text-2xl font-bold">Adicione um motivo de bloqueio</h2>
          <button @click="isCreateBlockingReasonModalOpen = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18"
                stroke="#090909"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#090909"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="flex flex-col gap-4 py-4">
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg">Motivo</label>
            <textarea type="text" placeholder="ex: "></textarea>
          </div>
        </div>
        <div class="font-inter flex justify-between pt-8 submit-wrapper">
          <button
            class="bg-emerald-600 font-bold text-white px-12 py-3 rounded-3xl"
          >
            Enviar
          </button>
          <button @click="isCreateBlockingReasonModalOpen = false">
            Cancelar
          </button>
        </div>
      </div>
    </MyModal>

    <MyModal
      :isOpen="isEditPartnersModalOpen"
      :toggleModal="(v) => (isEditPartnersModalOpen = v)"
      :width="'36rem'"
      :height="'40rem'"
      :id="'edit-partner-modal'"
    >
      <div class="p-8">
        <div class="flex items-center font-inter justify-between mb-4">
          <h2 class="text-2xl font-bold">Adicione um sócio</h2>
          <button @click="isEditPartnersModalOpen = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18"
                stroke="#090909"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#090909"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="flex flex-col gap-4 py-4">
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg">Nome</label>
            <input type="text" placeholder="ex: João Silva" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-6">
            <label class="font-bold text-lg">Documento</label>
            <input type="text" placeholder="ex: 000.000.000-00" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-12">
            <label class="font-bold text-lg">Porcentagem</label>
            <input type="number" placeholder="ex: 15%" />
          </div>
          <div class="font-inter flex flex-col gap-2 col-span-12">
            <label class="font-bold text-lg">Data de nascimento</label>
            <input type="text" placeholder="ex: 07/08/2000" />
          </div>
        </div>
        <div class="font-inter flex justify-between pt-8 submit-wrapper">
          <button
            class="bg-emerald-600 font-bold text-white px-12 py-3 rounded-3xl"
          >
            Enviar
          </button>
          <button @click="isEditPartnersModalOpen = false">Cancelar</button>
        </div>
      </div>
    </MyModal>
  </div>
</template>

<script lang="ts" setup>
import MyModal from "@/components/modal/MyModal.vue";
import { ref } from "vue";

const isCreateBlockingReasonModalOpen = ref(false);
const isEditPartnersModalOpen = ref(false);
</script>

<style lang="scss">
.show-company {
  input {
    height: 3.4375rem;
    border-radius: 0.875rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px;
    outline: unset;
  }
  .go-back {
    transition: all 0.2s ease;
    &__icon-wrapper {
      transition: all 0.2s ease;
      path {
        transition: all 0.2s ease;
      }
    }
    &:hover {
      color: #059669;
    }
    &:hover .go-back__icon-wrapper {
      background: #059669;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
  .add {
    transition: all 0.2s ease;
    path {
      transition: all 0.2s ease;
    }
    &:hover {
      background: #059669;
      color: #fff;
      path {
        stroke: #fff;
      }
    }
  }
  #edit-partner-modal {
    .submit-wrapper {
      border-radius: 1rem;
      background: var(--branco, #fff);
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      right: 2rem;
    }
  }
  #create-blocking-reason-modal {
    textarea {
      border-radius: 0.875rem;
      border: 1px solid var(--cinza-medio, #d3d3d3);
      padding: 15px 20px;
      outline: unset;
      height: 17rem;
    }
    .submit-wrapper {
      border-radius: 1rem;
      background: var(--branco, #fff);
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      right: 2rem;
    }
  }
}
</style>
