import useCookie from "@/composables/useCookie";
import axios from "axios";

const { getCookie, removeCookie } = useCookie();

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

axiosClient.interceptors.request.use(function (request) {
  request.headers.Authorization = `Bearer ${getCookie("auth_token")}`;
  return request;
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      removeCookie("auth_token");
      removeCookie("authenticated_user");
      removeCookie("is2AF");
      localStorage.removeItem("hasValidated2AF")

      window.location.href = process.env?.VUE_APP_LOGIN_URL || "/login";
    }

    if (error.response && error.response.status === 403 && error.response?.data.errors.find((e: any) => e.uniqueCode === "2AF:REQUEST_INVALID")) {
      localStorage.removeItem("hasValidated2AF")
      window.location.reload()
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
