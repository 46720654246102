<template>
  <div class="create-customer pt-12 sm:pt-6">
    <div class="flex">
      <RouterLink
        :to="{ name: 'companies.query' }"
        class="flex items-center gap-2 mb-8 go-back"
      >
        <div class="bg-zinc-100 p-3 rounded-full go-back__icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67"
              stroke="#090909"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h1 class="text-3xl font-bold font-nunito">Cadastrar cliente</h1>
      </RouterLink>
    </div>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-6 flex flex-col gap-4">
        <section class="flex flex-col gap-4 grid grid-cols-12">
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">CPF/CNPJ</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: 000.000.000-00"
              v-maska="
                (payload.document ?? '').length <= 14
                  ? '###.###.###-###'
                  : '##.###.###/####-##'
              "
              v-model="payload.document"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Nome</strong> (obrigatório)</label
            >
            <input type="text" placeholder="ex: João" v-model="payload.name" />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Email</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: email@gmail.com"
              v-model="payload.email"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Telefone</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: (99) 9 9999-9999"
              v-model="payload.phone_number"
              v-maska="'(##) # ####-####'"
            />
          </div>
          <div class="col-span-12 pt-4">
            <h2 class="text-xl font-inter">
              <strong>Endereço do cliente</strong>
              <small class="text-md">(opcional)</small>
            </h2>
            <p class="text-6f font-inter text-sm">
              Adicione o máximo de informações do endereço do cliente.
            </p>
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Cep</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: 99999-999"
              v-maska="'#####-###'"
              v-model="payload.address!.postal_code"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Rua</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: Rua josé..."
              v-model="payload.address!.street"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Número</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: 2341"
              v-model="payload.address!.number"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Bairro</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: Aldeota"
              v-model="payload.address!.neighborhood"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Cidade</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: Fortaleza"
              v-model="payload.address!.city"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Estado</strong> (obrigatório)</label
            >
            <input
              type="text"
              placeholder="ex: Rj"
              v-model="payload.address!.state"
            />
          </div>
          <div
            class="font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm"
          >
            <label for=""
              ><strong class="text-lg">Complemento</strong> (opcional)</label
            >
            <input
              type="text"
              placeholder="ex: Alto"
              v-model="payload.address!.complement"
            />
          </div>
        </section>
      </div>
      <section class="col-span-12 sm:col-span-6 flex flex-col gap-2">
        <section class="flex flex-col gap-2">
          <div class="font-inter">
            <label for=""
              ><strong class="text-lg">Motivo de Bloqueio</strong>
              (obrigatório)</label
            >
            <p class="text-6f font-inter text-sm">
              Adicione o motivo pelo qual você está denunciando essa empresa
            </p>
          </div>
          <div class="flex flex-col gap-4 py-4">
            <div class="font-inter flex flex-col gap-2 col-span-6">
              <textarea
                type="text"
                placeholder="ex: Esta empresa não passou no compliance"
                v-model="payload.complaint_reason"
              ></textarea>
            </div>
          </div>
        </section>
      </section>
      <nav>
        <input
          type="button"
          value="Criar"
          @click="createCustomer"
          :disabled="
            !payload.name ||
            !payload.document ||
            (payload.document?.length != 14 &&
              payload.document?.length != 18) ||
            !payload.email ||
            payload.phone_number?.length != 16 ||
            !payload.complaint_reason ||
            !isAddressValid
          "
          v-if="!isLoading"
        />
        <SpinnerLoader v-else />
        <input
          type="button"
          value="Cancelar"
          @click="$router.push({ name: 'companies.query' })"
        />
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { vMaska } from "maska/vue";
import { isLoading } from "@/global/states/GlobalState";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import axiosClient from "@/api/axios";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import ICustomer from "@/interfaces/customers/ICustomer";

const route = useRoute();
const payload = ref<Partial<ICustomer & { complaint_reason: string }>>({
  document: route.query.document as string,
  address: {},
});
const isAddressValid = computed(() => {
  if (payload.value.address?.postal_code) {
    if (payload.value.address?.postal_code.length !== 9) {
      return false;
    }

    return (
      payload.value.address.street &&
      payload.value.address.number &&
      payload.value.address.neighborhood &&
      payload.value.address.city &&
      payload.value.address.state
    );
  }

  return true;
});

function createCustomer() {
  isLoading.value = true;

  axiosClient
    .post("/customers", payload.value)
    .then(() => {
      payload.value = { address: {} };
      toast.success("Cliente cadastrado com sucesso");
    })
    .finally(() => (isLoading.value = false));
}
</script>

<style lang="scss">
.create-customer {
  input:disabled {
    background: #f1f1f1 !important;
    color: #6f6f6f !important;
    cursor: not-allowed !important;
  }
  input {
    height: 3.4375rem;
    border-radius: 0.875rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px;
    outline: unset;
    color: $primary-color;
    font-family: "Inter", sans-serif;
    &::placeholder {
      color: #484848;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: color 0.2s;
    }
  }
  textarea {
    border-radius: 0.875rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px;
    outline: unset;
    height: 17rem;
  }
  .go-back {
    transition: all 0.2s ease;
    &__icon-wrapper {
      transition: all 0.2s ease;
      path {
        transition: all 0.2s ease;
      }
    }
    &:hover {
      color: #059669;
    }
    &:hover .go-back__icon-wrapper {
      background: #059669;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
  .add {
    transition: all 0.2s ease;
    path {
      transition: all 0.2s ease;
    }
    &:hover {
      background: #059669;
      color: #fff;
      path {
        stroke: #fff;
      }
    }
  }
  #edit-partner-modal {
    .submit-wrapper {
      border-radius: 1rem;
      background: var(--branco, #fff);
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      right: 2rem;
    }
  }
  #create-blocking-reason-modal {
    .submit-wrapper {
      border-radius: 1rem;
      background: var(--branco, #fff);
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      right: 2rem;
    }
  }
  nav {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 32px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    position: fixed;
    bottom: 5px;
    column-gap: 20px;
    margin: auto 0 0 0;
    input {
      border: none;
      cursor: pointer;
      &:first-child {
        width: 100%;
        max-width: 464px;
        height: 60px;
        border-radius: 30px;
        background: $primary-color;
        color: #fff;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: background 0.2s;
        &:hover {
          background: $primary-color-hover;
        }
      }
      &:last-child {
        color: #737373;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: unset;
        transition: color 0.2s;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .create-customer {
    padding-bottom: 5.5rem;
    nav {
      width: 100vw;
      position: fixed;
      left: 0;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
      background: #fff;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 15px;
      box-sizing: border-box;
      z-index: 4;
    }
  }
}
</style>
