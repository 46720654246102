<template>
  <div id="profile" class="pt-12 sm:pt-6">
      <label @click="$router.push({ name: 'companies.query' })" class="back">
          <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </figure>
          <p>Perfil</p>
      </label>
      <input autocomplete='off' type="radio" name="profile" id="profile-register" checked>
      <input autocomplete='off' type="radio" name="profile" id="profile-password">
      <main>
          <div class="tab">
            <label for="profile-register">Cadastro</label>
            <label for="profile-password">Senha</label>
          </div>
          <form class="register">
            <section>
              <label for="name"><strong>Nome</strong> (obrigatório)</label>
              <input autocomplete='off' type="text" :value="user.name" name="name" id="name" disabled>
            </section>
            <section>
              <label for="email"><strong>E-mail</strong> (obrigatório)</label>
              <input autocomplete='off' :value="user.email" type="text" name="email" id="email" disabled>
            </section>
          </form>
          <form class="password">
            <section>
              <label for="new-password"><strong>Senha atual</strong> (obrigatório)</label>
              <input autocomplete='off' :type="showCurrentPassword ? 'text':'password'" v-model="profilePayload.oldPassword" name="new-password" id="new-password">
              <button type="button" @click="showCurrentPassword = !showCurrentPassword">
                <svg v-if="showCurrentPassword" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/eye">
                  <g id="eye">
                  <path id="Vector" d="M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.41998 13.9799 8.41998 11.9999C8.41998 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M12 20.2702C15.53 20.2702 18.82 18.1902 21.11 14.5902C22.01 13.1802 22.01 10.8102 21.11 9.40021C18.82 5.80021 15.53 3.72021 12 3.72021C8.47003 3.72021 5.18003 5.80021 2.89003 9.40021C1.99003 10.8102 1.99003 13.1802 2.89003 14.5902C5.18003 18.1902 8.47003 20.2702 12 20.2702Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  </g>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.47 14.53L2 22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M21.9998 2L14.5298 9.47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </section>
            <section>
              <label for="new-password"><strong>Nova senha</strong> (obrigatório)</label>
              <input autocomplete='off' :type="showNewPassword ? 'text':'password'" v-model="profilePayload.newPassword" name="new-password" id="new-password">
              <button type="button" @click="showNewPassword = !showNewPassword">
                <svg v-if="showNewPassword" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/eye">
                  <g id="eye">
                  <path id="Vector" d="M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.41998 13.9799 8.41998 11.9999C8.41998 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M12 20.2702C15.53 20.2702 18.82 18.1902 21.11 14.5902C22.01 13.1802 22.01 10.8102 21.11 9.40021C18.82 5.80021 15.53 3.72021 12 3.72021C8.47003 3.72021 5.18003 5.80021 2.89003 9.40021C1.99003 10.8102 1.99003 13.1802 2.89003 14.5902C5.18003 18.1902 8.47003 20.2702 12 20.2702Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  </g>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.47 14.53L2 22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M21.9998 2L14.5298 9.47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </section>
            <section>
              <label for="repeat-new-password"><strong>Repita a nova senha</strong> (obrigatório)</label>
              <input autocomplete='off' :type="showRepeatNewPassword ? 'text':'password'" v-model="profilePayload.newPasswordConfirmation" name="repeat-new-password" id="repeat-new-password">
              <button type="button" @click="showRepeatNewPassword = !showRepeatNewPassword">
                <svg v-if="showRepeatNewPassword" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/eye">
                  <g id="eye">
                  <path id="Vector" d="M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.41998 13.9799 8.41998 11.9999C8.41998 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M12 20.2702C15.53 20.2702 18.82 18.1902 21.11 14.5902C22.01 13.1802 22.01 10.8102 21.11 9.40021C18.82 5.80021 15.53 3.72021 12 3.72021C8.47003 3.72021 5.18003 5.80021 2.89003 9.40021C1.99003 10.8102 1.99003 13.1802 2.89003 14.5902C5.18003 18.1902 8.47003 20.2702 12 20.2702Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  </g>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.47 14.53L2 22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M21.9998 2L14.5298 9.47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </section>
          </form>
          <nav>
            <input v-if="!isUpdating" autocomplete='off' @click="updateProfile" :disabled="!profilePayload.newPassword || (profilePayload.newPassword && (profilePayload.newPassword != profilePayload.newPasswordConfirmation || profilePayload.newPassword?.length < 8)) ? true : false" type="button" id="profile-update-password" value="Salvar alterações">
            <SpinnerLoaderButton v-else :height="60" :maxWidth="464" />
          </nav>
      </main>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue'
import axiosClient from '@/api/axios'
import useCookie from '@/composables/useCookie'
import { toast } from "vue3-toastify";
import SpinnerLoaderButton from "@/components/loader/SpinnerLoaderButton.vue";


const { getCookie } = useCookie()
const user = ref<any>(getCookie('authenticated_user') ? JSON.parse(getCookie('authenticated_user') as string) : { address: {} })
const isUpdating = ref(false)

const profilePayload = ref({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })

watch(() => user.value, () => {
  profilePayload.value = ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })
})

function updateProfile () {
  isUpdating.value = true

  axiosClient.post('/accounts/password/change', profilePayload.value).then((res) => {
    toast.success('Senha atualizada com sucesso')
  })
  .catch((error) => {
    toast.error('Senha atual incorreta')
  })
  .finally(() => isUpdating.value = false)
}

const showCurrentPassword = ref<boolean>(false)
const showNewPassword = ref<boolean>(false)
const showRepeatNewPassword = ref<boolean>(false)

onMounted(() => {

})
</script>

<style lang="scss">
#profile{
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .back{
      display: flex;
      align-items: center;
      column-gap: 16px;
      cursor: pointer;
      margin: 0 auto 45px 0;
      animation: enterContent ease .4s;
      &:hover{
          figure{
            background: $primary-color;
          }
          svg{
            stroke: #FFF;
          }
          p{
            color: $primary-color;
          }
      }
      figure{
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          border-radius: 50px;
          background: #F5F5F5;
          transition: background .2s;
          svg{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          stroke: #090909;
          transition: stroke .2s;
          }
      }
      p{
          color: #090909;
          font-family: 'Nunito', sans-serif;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
          transition: color .2s
      }
  }
  input[type='radio']{
      display: none;
      &#profile-register:checked{
        & ~ main{
          .tab{
            label:first-child{
              background: $primary-color;
              color: #FFF;
            }
          }
          .register{
            display: flex;
          }
          nav{
            #profile-update-infos{
              display: block;
            }
          }
        }
      }
      &#profile-password:checked{
        & ~ main{
          .tab{
            label:last-child{
              background: $primary-color;
              color: #FFF;
            }
          }
          .password{
            display: flex;
          }
          nav{
            #profile-update-password{
              display: block;
            }
          }
        }
      }
  }
  main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    .tab{
      width: 100%;
      display: flex;
      align-content: center;
      column-gap: 24px;
      margin: 0 0 40px 0;
      label{
        height: 38px;
        padding: 20px;
        display: flex;
        align-items: center;
        color: #090909;
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        background: #F1F1F1;
        border-radius: 30px;
        box-sizing: border-box;
        transition: background .2s, color .2s;
        cursor: pointer;
        &:hover{
            background: $primary-color;
            color: #FFF;
        }
      }
    }
    form{
      width: 100%;
      height: 100%;
      display: none;
      flex-direction: column;
      align-content: center;
      &.register{
        section:nth-child(4){
          margin: 0 0 45px 0;
        }
      }
      &.password{
        section:nth-child(2){
          margin: 0 0 45px 0;
        }
      }
      section{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        position: relative;
        margin: 0 0 28px 0;
        animation: enterContent ease .4s;
        label{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 0 13px 0;
          strong{
            font-size: 20px;
          }
        }
        input{
          width: 100%;
          height: 55px;
          padding: 0 18px;
          border-radius: 16px;
          border: none;
          background: #F5F5F5;
          box-sizing: border-box;
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &::placeholder{
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
          }
          &:focus{
            outline: none;
            color: $primary-color;
            padding: 0 16px !important;
            &::placeholder{
              color: transparent;
            }
            & ~ button{
              svg{
                stroke: $primary-color !important;
              }
            }
          }
          &:read-only{
            cursor: default;
            color: $primary-color !important;
          }
        }
        svg{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          stroke: #090909;
          position: absolute;
          bottom: 15px;
          right: 20px;
          transition: stroke .2s;
          cursor: pointer;
          &:hover{
            stroke: $primary-color;
          }
        }
      }
    }
    nav{
      width: 100%;
      input[type='button']{
        display: none;
        width: 100%;
        height: 60px;
        border: none;
        border-radius: 30px;
        background: $primary-color;
        color: #FFF;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px;
        margin: auto 0 0 0;
        transition: background .2s;
        cursor: pointer;
        &:hover{
          background: $primary-color-hover;
        }
        &:disabled{
          background: #F1F1F1 !important;
          color: #6F6F6F !important;
          cursor: not-allowed !important;
        }
      }
    }
  }
}

@media(max-width: 425px){
  #profile{
    max-width: unset;
    .back{
      margin: 0 auto 40px 0;
    }
    main{
      nav{
        animation: enterToBottom ease 0.9s;
        width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
        padding: 15px;
        box-sizing: border-box;
        z-index: 4;
      }
      .tab{
        justify-content: center;
      }
      form{
        margin: 0 0 50px 0;
        .photo{
          h1{
            display: none;
          }
          .data{
            justify-content: center;
            p{
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>