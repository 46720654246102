<template>
  <div class="create-company pt-12 sm:pt-6">
    <div class="flex">
      <RouterLink
        :to="{ name: 'companies.query' }"
        class="flex items-center gap-2 mb-8 go-back"
      >
        <div class="bg-zinc-100 p-3 rounded-full go-back__icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67"
              stroke="#090909"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h1 class="text-3xl font-bold font-nunito">Cadastrar empresa</h1>
      </RouterLink>
    </div>
    <!-- <ul class="font-inter flex mb-8">
            <li class="flex justify-center items-center gap-2 bg-zinc-100 p-4 py-2 rounded-3xl">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16.4405 8.90039C20.0405 9.21039 21.5105 11.0604 21.5105 15.1104V15.2404C21.5105 19.7104 19.7205 21.5004 15.2505 21.5004H8.74047C4.27047 21.5004 2.48047 19.7104 2.48047 15.2404V15.1104C2.48047 11.0904 3.93047 9.24039 7.47047 8.91039" stroke="#090909" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 2V14.88" stroke="#090909" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.3484 12.6504L11.9984 16.0004L8.64844 12.6504" stroke="#090909" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p>Baixar</p>
            </li>
        </ul> -->

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-6 flex flex-col gap-6">
        <section class="flex flex-col gap-4 grid grid-cols-12">
          <div class="font-inter flex flex-col gap-2 col-span-12">
            <label for=""
              ><strong class="text-lg">CPF/CNPJ</strong> (obrigatório)</label
            >
            <input
              type="tel"
              v-model="payload.document"
              @blur="payload.document.length !== 64 ? toast.error('Por favor, criptografe o número do documento utilizando a criptografia sha256') : ''"
            />
          </div>
        </section>
      </div>
      <section class="col-span-12 sm:col-span-6 flex flex-col gap-2">
        <section class="flex flex-col gap-2">
          <div class="font-inter">
            <label for=""
              ><strong class="text-lg">Motivo de Bloqueio</strong>
              (obrigatório)</label
            >
            <p class="text-6f font-inter text-sm">
              Adicione o motivo pelo qual você está denunciando essa empresa
            </p>
          </div>
          <div class="flex flex-col gap-4 py-4">
            <div class="font-inter flex flex-col gap-2 col-span-6">
              <textarea
                type="text"
                placeholder="ex: Esta empresa não passou no compliance"
                v-model="payload.reason"
              ></textarea>
            </div>
          </div>
        </section>
      </section>
      <nav>
        <input
          type="button"
          value="Criar"
          @click="createCompany"
          :disabled="
            // !payload.name ||
            //!payload.name_fantasy ||
            !payload.document ||
            (payload.document?.length !== 64) ||
            // !payload.email ||
            // payload.phone?.length != 16 ||
            !payload.reason
          "
          v-if="!isLoading"
        />
        <SpinnerLoader v-else />
        <input
          type="button"
          value="Cancelar"
          @click="$router.push({ name: 'companies.query' })"
        />
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
// import { vMaska } from "maska/vue";
import { isLoading } from "@/global/states/GlobalState";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import axiosClient from "@/api/axios";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

const route = useRoute();
const payload = ref<any>({ document: route.query.document });
const isEditPartnersModalOpen = ref(false);
// const isFetchCnpjRequestLoading = ref(false);

function createCompany() {
  isLoading.value = true;

  axiosClient
    .post("/companies/register", payload.value)
    .then(() => {
      payload.value = {};
      toast.success("Empresa cadastrada com sucesso");
    })
    .finally(() => (isLoading.value = false));
}

// function fetchCnpjData() {
//   payload.value.name = "";
//   payload.value.name_fantasy = "";

//   if (payload.value.document.length === 18) {
//     isFetchCnpjRequestLoading.value = true;

//     axiosClient
//       .get(
//         `https://publica.cnpj.ws/cnpj/${payload.value.document.replace(
//           /\D/g,
//           ""
//         )}`
//       )
//       .then((res: any) => {
//         payload.value.name = res.data.razao_social;
//         payload.value.name_fantasy = res.data.estabelecimento.nome_fantasia;
//       })
//       .catch(() => {
//         toast.error(
//           "Erro ao buscar razão social e nome fantasia, verifique se o CNPJ está correto"
//         );
//         payload.value.document = "";
//       })
//       .finally(() => (isFetchCnpjRequestLoading.value = false));
//   }
// }
</script>

<style lang="scss">
.create-company {
  input:disabled {
    background: #f1f1f1 !important;
    color: #6f6f6f !important;
    cursor: not-allowed !important;
  }
  input {
    height: 3.4375rem;
    border-radius: 0.875rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px;
    outline: unset;
    color: $primary-color;
    font-family: "Inter", sans-serif;
    &::placeholder {
      color: #484848;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: color 0.2s;
    }
  }
  textarea {
    border-radius: 0.875rem;
    border: 1px solid var(--cinza-medio, #d3d3d3);
    padding: 15px 20px;
    outline: unset;
    height: 17rem;
  }
  .go-back {
    transition: all 0.2s ease;
    &__icon-wrapper {
      transition: all 0.2s ease;
      path {
        transition: all 0.2s ease;
      }
    }
    &:hover {
      color: #059669;
    }
    &:hover .go-back__icon-wrapper {
      background: #059669;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
  .add {
    transition: all 0.2s ease;
    path {
      transition: all 0.2s ease;
    }
    &:hover {
      background: #059669;
      color: #fff;
      path {
        stroke: #fff;
      }
    }
  }
  #edit-partner-modal {
    .submit-wrapper {
      border-radius: 1rem;
      background: var(--branco, #fff);
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      right: 2rem;
    }
  }
  #create-blocking-reason-modal {
    .submit-wrapper {
      border-radius: 1rem;
      background: var(--branco, #fff);
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 1rem;
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      right: 2rem;
    }
  }
  nav {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 32px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    position: fixed;
    bottom: 5px;
    column-gap: 20px;
    margin: auto 0 0 0;
    input {
      border: none;
      cursor: pointer;
      &:first-child {
        width: 100%;
        max-width: 464px;
        height: 60px;
        border-radius: 30px;
        background: $primary-color;
        color: #fff;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: background 0.2s;
        &:hover {
          background: $primary-color-hover;
        }
      }
      &:last-child {
        color: #737373;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: unset;
        transition: color 0.2s;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .create-company {
    padding-bottom: 5.5rem;
    nav {
      width: 100vw;
      position: fixed;
      left: 0;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
      background: #fff;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 15px;
      box-sizing: border-box;
      z-index: 4;
    }
  }
}
</style>
